import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Home from './Home/Home';
import ServicesSection from './Services/ServicesSection';
// import About from './About/About';
// import Contact from './Contact/Contact';

function App() {
  return (
    
    <Router>
      <div className="App">
        {/* Menu */}
        <nav className="navbar">
          <div className="container row">
            <p><img className="logo" src="test4.png" alt="" width="80px"/></p>
            <p><Link to="/" className="titre">Scriptales solutions</Link></p>
            <ul className="nav-menu">
              <li><Link to="/">Accueil</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/about">À Propos</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>
        </nav>

        {/* Routes */}
        <Routes>
          <Route path="/services" element={<ServicesSection variant="fullpage"/>} />
          {/* <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} /> */}
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>

  );
}

export default App;
