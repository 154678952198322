import React from 'react';
import Header from '../Header/Header';
import ServicesSection from '../Services/ServicesSection';

// Header Component
const Home = () => (
  <>
    <Header/>
    <ServicesSection variant="homepage"/>
  </>
);

export default Home;