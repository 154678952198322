import React from 'react';
import './Header.css';
import { ArrowDownCircle, Mail } from 'lucide-react';

// Header Component
const Header = () => (
  <>
    {/* En-tête */}
    <header id="home" className="header">
        <div className="header-content">
            <h1>Un logo unique, à l'image de votre entreprise ?</h1>
            <p>Nous créons le logo qui vous représente vraiment, mais bien plus encore...!</p>
            <a href="#services" className="btn neon-btn">Découvrez nos services</a>
        </div>
        
        <a href="#services"><ArrowDownCircle className="custom-icon wiggle" size={80} /></a>
        <div class="add-on">
            <blockquote>
                &ldquo;&nbsp;Si j'avais demandé aux gens ce qu'ils voulaient, ils m'auraient dit : des chevaux plus rapides.&nbsp;&rdquo; - <span class="text-bold">Henry Ford</span>
            </blockquote>
        </div>
    </header>
    
  </>
);

export default Header;